'use client';

import { Button } from '@formo/ui';
import {
  AppRouterErrorProps,
  appRouterSsrErrorHandler,
} from '@highlight-run/next/ssr';

export default appRouterSsrErrorHandler(({ reset }: AppRouterErrorProps) => {
  return (
    <div className="h-screen w-screen flex justify-center items-center flex-col gap-4">
      <h2 className="text-9xl font-black">500</h2>
      <h2 className="text-xl font-light">
        Internal server error. Please try again later.
      </h2>
      <Button onClick={() => reset()}>Try again</Button>
    </div>
  );
});
